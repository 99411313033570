@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;700&display=swap');

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: white;
}

.logo {
  position: fixed;
  top: 20px;
  left: 20px;
  width: 50px;
  height: 50px;
  z-index: 1000; /* Ensure it stays on top of other elements */
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  transition: all 1s ease;
}

.container.move-up {
  justify-content: center;
  align-items: center;
}

.text {
  font-size: 5rem;
  color: #f0e9e9;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  animation: pulse 2s infinite;
  transition: all 1s ease;
}

.text.small {
  font-size: 3rem;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0;
  animation: fadeIn 1s forwards;
  margin-top: 20px;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

.pitch-deck {
  width: 90%;
  min-width: 500px;
  max-width: 1000px;
  height: 300px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
}

.download-button {
  margin-bottom: 20px;
  padding: 10px 20px;
  font-size: 1rem;
  color: white;
  background-color: darkgreen;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.download-button:hover {
  background-color: green;
}

.waiting-list-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.waiting-list-form h2 {
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  margin-bottom: 10px;
}

.waiting-list-form input {
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 10px;
  width: 100%;
  max-width: 300px;
}

.waiting-list-form button {
  padding: 10px 20px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  background-color: darkgreen;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.waiting-list-form button:hover {
  background-color: green;
}
